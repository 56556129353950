import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { withCSContext } from 'services/ApolloClient';
import { gql, useLazyQuery } from '@apollo/client';

const CLIENT_INFORMATION = gql`
    query ClientInformation {
        contact{
            relationshipInformation {
                contactType {
                    value
                }
            }
        }
    }
`;

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataPost: null,
    errorPost: null,
    isLoadingPost: false,
    dataCalculate: null,
    errorCalculate: null,
    isLoadingCalculate: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setPostData':
            return { ...state, dataPost: action.payload };
        case 'setPostError':
            return { ...state, errorPost: action.payload };
        case 'setPostIsLoading':
            return { ...state, isLoadingPost: action.payload };
        case 'setCalculateData':
            return { ...state, dataCalculate: action.payload };
        case 'setCalculateError':
            return { ...state, errorCalculate: action.payload };
        case 'setCalculateIsLoading':
            return { ...state, isLoadingCalculate: action.payload };
        default:
            return state;
    }
};

export const useQuestionnaire = (options = {}) => {
    const {
        clientId,
        productId: productIdOption,
        questionnaireTypeId: questionnaireTypeIdOption = 1,
        loadInitially = false,
    } = options;
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n: { language } } = useTranslation();

    const [getClientInfo] = useLazyQuery(CLIENT_INFORMATION, {
        context: withCSContext({ queryParams: { contactId: clientId } }),
    });

    // Callbacks
    const getQuestionnaire = useCallback(async ({
        productId = productIdOption,
        questionnaireTypeId = questionnaireTypeIdOption,
    } = {}) => {
        if (!productId) {
            return null;
        }
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        let contactTypeId = 1;

        try {
            const { data: dataClientRaw } = await getClientInfo();
            const contactType = dataClientRaw?.contact?.relationshipInformation?.contactType?.value;

            contactTypeId = contactType === 340 ? 2 : contactTypeId;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }

        const params = {
            Language: language,
            ProductId: productId,
            ContactTypeId: contactTypeId,
            ...(questionnaireTypeId !== null ? { QuestionnaireTypeId: questionnaireTypeId } : {}),
        };

        try {
            const response = await SM.compliance('filterQuestionnaires', [params]);

            dispatch({ type: 'setData', payload: response?.data?.[0] });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, productIdOption, questionnaireTypeIdOption]);
    const validateQuestionnaire = useCallback(({
        data = state.data,
        groupId,
        answers = {},
        errorMessage,
    } = {}) => {
        const errors = {};
        const groupToValidate = groupId
            ? (data?.Groups || []).filter(({ Id }) => Id === groupId)
            : (data?.Groups || []);
        const answersToValidate = groupId
            ? answers[groupId] || {}
            : Object.values(answers || {}).reduce((acc, group) => ({ ...acc, ...group }), {});
        const questions = groupToValidate
            .map((group) => group.Steps
                .map((step) => step.Questions
                    .map((question) => question).flat()).flat()).flat();

        questions.forEach((question) => {
            const {
                Id, Type, MinimumNumberOfAnswers, MaximumNumberOfAnswers,
            } = question;
            const value = answersToValidate[Id];
            const isRequired = Type === 'MultiAnswer' ? MinimumNumberOfAnswers > 0 : true;

            if (!Object.keys(answersToValidate).includes(Id.toString()) && isRequired) {
                errors[Id] = errorMessage || t('validation.mandatoryField');
            }
            if (value?.length < MinimumNumberOfAnswers) {
                errors[Id] = `${t('validation.questionnaire.minimumNumberOfAnswers')}: ${MinimumNumberOfAnswers}`;
            }
            if (value?.length > MaximumNumberOfAnswers) {
                errors[Id] = `${t('validation.questionnaire.maximumNumberOfAnswers')}: ${MaximumNumberOfAnswers}`;
            }
        });
        const isValid = Object.keys(errors).length === 0;

        return { isValid, errors };
    }, [state.data, t]);
    const postQuestionnaire = useCallback(async ({
        answers,
    } = {}) => {
        dispatch({ type: 'setPostError', payload: null });
        dispatch({ type: 'setPostIsLoading', payload: true });

        try {
            const response = await SM.compliance('postAnswers', [clientId, answers]);

            dispatch({ type: 'setPostData', payload: response?.data });
            dispatch({ type: 'setPostIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setPostError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setPostIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId]);
    const calculateRisk = useCallback(async ({
        productId = productIdOption,
    } = {}) => {
        dispatch({ type: 'setCalculateError', payload: null });
        dispatch({ type: 'setCalculateIsLoading', payload: true });

        try {
            const response = await SM.compliance('calculateRisk', [clientId, productId, false, '', true, language]);

            dispatch({ type: 'setCalculateData', payload: response?.data });
            dispatch({ type: 'setCalculateIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setCalculateError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setCalculateIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language, productIdOption]);

    // Effects
    useEffect(() => {
        if (loadInitially) dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getQuestionnaire();
    }, [getQuestionnaire, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getQuestionnaire,
        validateQuestionnaire,
        dataPost: state.dataPost,
        errorPost: state.errorPost,
        isLoadingPost: state.isLoadingPost,
        postQuestionnaire,
        dataCalculate: state.dataCalculate,
        errorCalculate: state.errorCalculate,
        isLoadingCalculate: state.isLoadingCalculate,
        calculateRisk,
    };
};
