import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { ATRAM_DIRECTIONAL_ID } from 'constants/portfolioProducts';
import { useFormatting } from 'locale';
import { positionsColumns, positionsMobileColumns } from '../constants';

function Positions({
    data, isLoading, error, defaultExpandAllRows, productId,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedCurrency } = useFormatting();
    const isDirectional = ATRAM_DIRECTIONAL_ID.includes(productId);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('portfolios.noPositionsFound')}>
                <Table
                    className="positions-table"
                    data={data}
                    columns={
                        isMobileSize
                            ? positionsMobileColumns(t)
                            : positionsColumns({ t, isDirectional, getFormattedCurrency })
                    }
                    expandedColumn="name"
                    headerControls={{
                        selectControlsLabel: {
                            selectAll: t('headerControls.selectAll'),
                            reset: t('headerControls.reset'),
                        },
                    }}
                    defaultExpandAllRows={defaultExpandAllRows}
                    fixedLastColumn
                    sorting={isMobileSize ? null : { key: 'allocation', direction: 'desc' }}
                />
            </EmptyContent>
        </Preloader>
    );
}

Positions.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    Name: PropTypes.string,
                    Product: PropTypes.string,
                    Profile: PropTypes.string,
                    Value: PropTypes.string,
                    Performance: PropTypes.string,
                    Icon: PropTypes.string,
                    subRows: PropTypes.arrayOf(
                        PropTypes.shape({
                            Name: PropTypes.string,
                            Product: PropTypes.string,
                            Profile: PropTypes.string,
                            Value: PropTypes.string,
                            Performance: PropTypes.string,
                            Icon: PropTypes.string,
                        }),
                    ),
                }),
            ),
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    defaultExpandAllRows: PropTypes.bool,
    productId: PropTypes.number,
};

Positions.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    defaultExpandAllRows: false,
    productId: null,
};

export default Positions;
