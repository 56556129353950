import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting } from 'locale';
import { gql, useLazyQuery } from '@apollo/client';
import { adaptOrderBook } from '../adapters/adaptOrderBook';

const SEC_QUERY = gql`
    query Securities(
        $where: SecurityFilterInput!
        $take: Int! = 100
    ) {
        securities(where: $where, take: $take) {
            items {
                Id: id
                Name: name
                Currency: currency {
                    CurrencyCode: threeLetterIsoCode
                }
            }
        }
    }
`;

const initialState = {
    data: false,
    dataRaw: [],
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useOrderBook = (options) => {
    const {
        clientId,
        portfolioId,
        adaptData = adaptOrderBook,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);
    const [getSecurities] = useLazyQuery(SEC_QUERY);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Callbacks
    const getOrderBook = useCallback(async ({ adaptOptions } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        const params = { page: 1, pageSize: 100, orderBookStatusIds: [1, 2, 3, 4, 5, 7] };

        try {
            const response = await SM.portfolioManagement('postPortfolioOrderBook', [clientId, portfolioId, params]);

            let securitiesResponse;

            if (response?.data?.Results.length) {
                const SecurityIds = response?.data?.Results.map((i) => i.SecurityId);

                const { data: securitiesData } = await getSecurities({
                    variables: {
                        where: {
                            id: {
                                in: [...new Set(SecurityIds)],
                            },
                        },
                    },
                });

                securitiesResponse = securitiesData;
            }

            try {
                const adapted = adaptData(response.data, {
                    clientId,
                    portfolioId,
                    t,
                    getFormattedDate,
                    getFormattedNumber,
                    getFormattedCurrency,
                    securities: securitiesResponse?.securities?.items,
                    ...adaptOptions,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: response.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        clientId,
        language,
        portfolioId,
        t,
        getFormattedCurrency,
        getFormattedDate,
        getFormattedNumber,
    ]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getOrderBook();
    }, [getOrderBook, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getOrderBook,
    };
};
