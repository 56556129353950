import { getParent, roundAllocations, sum } from 'utils';
import {
    formatBigNumber,
    getfromDataObject,
    NA,
    percentIsZero,
    validateData,
    validateNumber,
} from 'utils/formatting';
import { sortAssets } from 'utils/sortingAllocation';
import { isLiquidity } from 'utils/portfolio';
import history from '../../../services/history';

export const redirectToReadOnlyPositionDetails = (baseUrl, positionId, tabId) => history.push(`${baseUrl}/${positionId}/${tabId}/overview`);

export const groupPositions = (data, {
    baseUrl,
    portfolioCurrency,
    isProposed,
    t,
    getFormattedNumber,
    getFormattedCurrency,
}) => {
    const roundedAllocation = data?.length ? roundAllocations(data, 100000, 1000) : [];
    const groupToValues = (data || []).reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAlloc = roundedAllocation.find((n) => n.id === securityData.Id).value;

        let customAttributes = {};

        try {
            customAttributes = JSON.parse(item.CustomAttributes);
        } catch (e) {
            // Do not throw error
        }

        const accParent = getParent(item.Security.AssetClass);
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);

        accumulator[accParent.Name] = obj[accParent.Name] || [];
        accumulator[accParent.Name].push({
            parentId: accParent.Id,
            ...(securityData.Type.Id === 1 && {
                accountId: item?.AccountId,
            }),
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            name: {
                label: name,
                link: isLiquidity(securityData) ? undefined : `${baseUrl}/${securityData.Id}${isProposed ? '' : '/2/overview'}`,
                maxLength: 34,
            },
            allocationData: roundedAlloc,
            allocation:
                `${getFormattedNumber(roundedAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            currency,
            units: getFormattedNumber(validateNumber(item.Quantity), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            performance: validateNumber(item.Performance),
            latestPrice: getFormattedNumber(
                validateNumber(item.ValuationPrice),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuation: getFormattedCurrency(
                validateNumber(item.InvestmentValuePortfolioCurrency),
                {
                    currency: portfolioCurrency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            subAssetClass: getfromDataObject(item.Security.AssetClass, 'Name'),
            typeId: getfromDataObject(item.Security.Type, 'Id'),
            type: getfromDataObject(item.Security.Type, 'Name'),
            country: getfromDataObject(item.Security.Country, 'Name'),
            region: getfromDataObject(item.Security.Country.Continent, 'Name'),
            subSector: getfromDataObject(item.Security.Sector, 'Name'),
            sector: getParent(item.Security.Sector).Name,
            fxRate: getFormattedNumber(item.FxRate, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            customAttributes,
            unrealizedGainLoss:
                customAttributes?.pnl
                    ? getFormattedNumber(parseFloat(customAttributes?.pnl), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    }) : '',
            costPrice: isLiquidity(securityData) ? '' : getFormattedNumber(item.BuyPrice, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            valuationInSecurityCurrency: getFormattedCurrency(
                validateNumber(item.InvestmentValueSecurityCurrency),
                {
                    currency: item.Security.Currency.CurrencyCode,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuationData: validateNumber(item.InvestmentValuePortfolioCurrency),
            performanceCCY: getFormattedNumber(validateNumber(item.MonetaryPerformance),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
            acquisitionCost:
                customAttributes?.acquisitionCost
                    ? getFormattedNumber(parseFloat(customAttributes?.acquisitionCost), {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    }) : '',
            actions: isLiquidity(securityData)
                ? {}
                : {
                    value: 'action',
                    actions: [
                        {
                            text: t('clientDashboard.portfolio.showMoreDetails'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 1,
                            ),
                        },
                        {
                            text: t('clientDashboard.portfolio.showPerformance'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 2,
                            ),
                        },
                    ],
                },
        });

        return accumulator;
    }, {});

    let groups = Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];

        const allocation = getFormattedNumber(
            percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData'))),
        );

        return {
            id: groupMembersData[0].parentId,
            valuation: getFormattedCurrency(sum(groupMembersData, 'valuationData'), {
                currency: portfolioCurrency,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            allocation: `${getFormattedNumber(allocation, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })}%`,
            allocationData: sum(groupMembersData, 'allocationData'),
            name: key,
            children: groupMembersData,
            icon: 'action',
            actions: {},
        };
    });

    groups = groups.map((groupItem) => {
        const resultGroupItem = { ...groupItem };

        resultGroupItem.valuation = groupItem.valuation;

        resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
            const resultGroupMemberItem = groupMemberItem;

            resultGroupMemberItem.performance = (groupMemberItem.performance * 100);
            resultGroupMemberItem.valuation = (groupMemberItem.valuation);
            resultGroupMemberItem.icon = 'action';

            return resultGroupMemberItem;
        });

        return resultGroupItem;
    });

    return sortAssets(groups);
};
